import '@/styles/globals.css'

import { NextPage } from 'next'
import { AppProps } from 'next/app'

//import { IBM_Plex_Sans } from 'next/font/google'

// NECESARIO PARA MOSTRAR LOS MAPAS DE LEAFLET
import 'leaflet/dist/leaflet.css'

import localFont from 'next/font/local'
import Script from 'next/script'
import { NextIntlProvider } from 'next-intl'
import { DefaultSeo } from 'next-seo'
import withTranslateRoutes from 'next-translate-routes'

import config from '../../next-seo.config'
import ErrorBoundary from './ErrorBoundary'

// @docs: https://nextjs.org/docs/pages/building-your-application/optimizing/fonts
const fontPlexSans = localFont({
  src: [
    {
      path: '../fonts/IBMPlexSans-Regular.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../fonts/IBMPlexSans-Medium.ttf',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../fonts/IBMPlexSans-Medium.ttf',
      weight: '500',
      style: 'italic',
    },
    {
      path: '../fonts/IBMPlexSans-SemiBold.ttf',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../fonts/IBMPlexSans-SemiBoldItalic.ttf',
      weight: '600',
      style: 'italic',
    },
    {
      path: '../fonts/IBMPlexSans-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../fonts/IBMPlexSans-BoldItalic.ttf',
      weight: '700',
      style: 'italic',
    },
  ],
})

const matomoUrl = process.env.NEXT_PUBLIC_MATOMO_URL
const siteId = process.env.NEXT_PUBLIC_MATOMO_SITE_ID

const App = (props: AppProps) => {
  const {
    Component,
    pageProps: { messages, ...pageProps },
  }: { Component: NextPage; pageProps: any } = props

  return (
    <ErrorBoundary fallback={<div></div>}>
      <div className={fontPlexSans.className}>
        {matomoUrl && siteId && (
          <Script
            id="matomo-script"
            strategy="afterInteractive" // Esto asegura que se ejecute después de la interacción inicial
            dangerouslySetInnerHTML={{
              __html: `
              var _paq = window._paq = window._paq || [];
              _paq.push(['trackPageView']);
              _paq.push(['enableLinkTracking']);
              (function() {
                var u = "${matomoUrl}";
                _paq.push(['setTrackerUrl', u + 'matomo.php']);
                _paq.push(['setSiteId', '${siteId}']);
                var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
                g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
              })();
            `,
            }}
          />
        )}
        <NextIntlProvider messages={messages}>
          <DefaultSeo {...config} />
          <Component {...pageProps} />
        </NextIntlProvider>
      </div>
    </ErrorBoundary>
  )
}

export default withTranslateRoutes(JSON.parse(`{"defaultLocale":"es","locales":["es","en","pt-pt","fr","de","it","ja","ko","zh-hans"],"routesTree":{"name":"","paths":{"default":""},"children":[{"name":"ErrorBoundary","paths":{"default":"ErrorBoundary"}},{"name":"sitemap","paths":{"default":"sitemap"}},{"name":"sitemap.xml","paths":{"default":"sitemap.xml"}},{"name":"my-itinerary","paths":{"default":"my-itinerary","es":"mi-itinerario","en":"my-itinerary","pt-pt":"meu-itinerario","fr":"mon-itineraire","de":"mein-reiseplan","it":"il-mio-itinerario","ja":"watashi-no-rutei","ko":"nae-yeohaeng","zh-hans":"wo-de-xingcheng"},"children":[{"name":"[id]","paths":{"default":":id"}}]},{"name":"[[...slug]]","paths":{"default":":slug*"}}]},"fallbackLng":{}}`), App)

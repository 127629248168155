import { DefaultSeoProps } from 'next-seo'

const config: DefaultSeoProps = {
  title: 'Madrid Turismo',
  description:
    'Descripción por defecto del portal web de turismo de la comunidad de Madrid',
  openGraph: {
    site_name: process.env.NEXT_PUBLIC_NEXTJS_BASE_URL,
    title: 'Madrid Turismo',
    description:
      'Descripción por defecto del portal web de turismo de la comunidad de Madrid',
    images: [
      {
        url: `${process.env.NEXT_PUBLIC_NEXTJS_BASE_URL}/madrid_desktop.webp`,
      },
    ],
  },
  /*Twitter will read the og:title, og:image and og:description tags for their card.
    Next-seo omits twitter:title, twitter:image and twitter:description to avoid duplication.
  */
  twitter: {
    cardType: 'summary_large_image',
    site: process.env.NEXT_PUBLIC_NEXTJS_BASE_URL,
  },
}

export default config
